import React from 'react';
import PrivacyPolicy from './PrivacyPolicy';

export default function MeetOurTeam(props) {
 
    return (
        <div>
            <PrivacyPolicy/>
        </div>
    );
}