import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CareersHeroBg from '../../assets/newImages/careershero.jpg';
import { motion } from 'framer-motion';
// import loadVariants from '../../variants';

// const variants = loadVariants();

const useStyles = makeStyles((theme) => ({
    sampleCareers: {
        marginTop: theme.spacing(5),
        marginBottom: theme.spacing(7),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(4),
            marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(5),
        },
    },
    sampleCareersPretitle: {
        fontFamily: 'Mukta',
        color: '#c29032',
        fontWeight: 400,
    },
    sampleCareersTitle: {
        fontFamily: 'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1.5),
        },
    },
    sampleCareersImg: {
        width: '100%',
        display: 'block',
    },
    sampleCareersSubtitle: {
        fontFamily: 'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1),
        },
    },
    sampleCareersBody: {
        fontFamily: 'Mukta',
        color: '#777',
        fontWeight: 300,
    },

}));

export default function SampleCareersHero(props) {

    const classes = useStyles();

    return (
        <Container >
            <Grid container className={classes.sampleCareers}>
                <Grid item xs={12}>
                        <motion.div
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: true }}
                            transition={{ duration: 1, }}
                            variants={{
                              visible: { opacity: 1, },
                              hidden: { opacity: 0,}
                            }}>
                        <Typography variant="body1" className={classes.sampleCareersPretitle}>WANT TO WORK WITH US?</Typography>
                        <Typography variant="h1" component="h1" className={classes.sampleCareersTitle}>Careers</Typography>
                    </motion.div>
                </Grid>
            </Grid>
            <Grid container spacing={4} alignItems='center'>
                <Grid item xs={12} sm={12} md={6}>
                <motion.div
                    initial='hidden'
                    whileInView='visible'
                    viewport={{ once: true }}
                    transition={{ duration: 1,}}
                    variants={{
                      visible: { opacity: 1,  },
                      hidden: { opacity: 0,  }
                    }}>
                        <img alt="text" src={CareersHeroBg} className={classes.sampleCareersImg}></img>
                    </motion.div>
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                        <motion.div
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true }}
                        transition={{ duration: 1,delay:0.5 }}
                        variants={{
                        visible: { opacity: 1, },
                        hidden: { opacity: 0, }
                        }}>
                        <Typography variant="h2" className={classes.sampleCareersSubtitle}>Why a Career at Law Counsellors?</Typography>
                        </motion.div>
                        <motion.div
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true }}
                        transition={{ duration: 1,delay:1 }}
                        variants={{
                        visible: { opacity: 1, },
                        hidden: { opacity: 0, }
                        }}>
                        <Typography variant="subtitle1" className={classes.sampleCareersBody}>Like many of our clients, Law Counsellors proves that sophisticated, cutting-edge work performed at the highest level isn’t always a function of size.</Typography>
                        </motion.div>
                        <motion.div
                        initial='hidden'
                        whileInView='visible'
                        viewport={{ once: true }}
                        transition={{ duration: 1,delay:1.5 }}
                        variants={{
                        visible: { opacity: 1, },
                        hidden: { opacity: 0, }
                        }}>
                        <Typography variant="subtitle1" className={classes.sampleCareersBody}>How do we do it? Keeping our focus on what is important to our clients instead of trying to be a legal superstore. We invite you to dig deeper into Law Counsellors to see what we have to offer YOU!</Typography>
                    </motion.div>
                </Grid>
            </Grid>
        </Container>
    );
}