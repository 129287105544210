/** @format */

exports.team = [
  {
    id: "akhilesh-dubey",
    title: "AKHILESH DUBEY",
    desn:"ADV.",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: " AKHILESH S DUBEY",
      subtitle:
        "All people are equal before the law. A good attorney is what makes a difference.",
      body1:
        "Akhilesh Sheshmani Dubey completed his LL. B from Mumbai University in 2004. He has also completed his Master of Laws from University of London specialising in International Dispute Resolution. Akhilesh Dubey is also admitted as a Solicitor to the Supreme Court of England and Wales in 2009 and a member of Law Society of England and Wales.",
      body2:
        "Following his legal studies, Akhilesh Dubey started practice at Bombay High Court from 2004 and has been in active practice since then. During his practice, Akhilesh Dubey has worked on a wide variety of cases involving, among other things, commercial law, labour laws, service matters, public interest litigation, human rights law, civil and criminal litigations. Akhilesh Dubey also has authored articles on legal topics which has been published by reputed publications.",
      body3:
        "During his legal practice Mr. Akhilesh Dubey has appeared for some of the biggest corporate houses and families and has reported judgments of Bombay High Courts to his credit.",
      desig: "Founder",
      flag: 1,
    },
  },
  {
    id: "vagish-mishra",
    title: "VAGISH MISHRA",
    desn:"ADV.",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "VAGISH MISHRA",
      subtitle:
        "All people are equal before the law. A good attorney is what makes a difference.",
      body1:
        "Vagish Mishra completed his LL. B from Mumbai University in 2004. advocate handling Criminal and Civil matter. as well as banking and Securitization Act",
      body2:
        "Following his legal studies, Vagish Mishra started practice at Bombay High Court from 2004 and has been in active practice since then. During his practice, Vagish Mishra has worked on a wide variety of cases involving, among other things, commercial law, labour laws, service matters, public interest litigation, human rights law, civil and criminal litigations.",
      body3:
        "During his legal practice Vagish Mishra has appeared for some of the biggest corporate houses and families and has reported judgments of Bombay High Courts to his credit.",
      desig: "Founder",
      flag: 1,
    },
  },
  {
    id: "rajendra-singh",
    desn:"ADV.",
    title: "RAJENDRA SINGH",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "RAJENDRA SINGH",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  // {
  //   id: "ahmad-ali-khan",
  //   desn:"ADV.",
  //   title: " AHMAD ALI KHAN",
  //   desc: {
  //     title: "ADVOCATE/SOLICITOR",
  //     name: "AHMAD AlI KHAN",
  //     subtitle:
  //     "",
  //   body1:
  //     "",
  //   body2:
  //     "",
  //   body3: "",
  //     desig: "Founder",
  //   },
  //   flag: 0,
  // },
  {
    id: "rahul-sharma",
    desn:"ADV.",
    title: "RAHUL SHARMA",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "RAHUL SHARMA",
      subtitle:
      "",
    body1:
      "Advocate Rahul Sharma, is an experience professional with about 14 years (and counting) of practice in various fields of Law. With an expertise in Civil, Criminal, Commercial and Corporate matters, he has appeared for our Clients in High Court Bombay, High Court Allahabad, District and Session Courts Mumbai as well as in other states, Civil Court Senior Division/Junior Divisions, Metropolitan Magistrate Courts, DRT, DRAT, NCLT Mumbai and  Kolkata, Consumer Forums, State Commissions, MRT, MAT and Supreme Court of India.",
    body2:
      "He has also filed numerous PILs, Writ Petitions and Testamentary matters under the guidance of Senior Advocates.",
    body3: "He has drafting skills and drafts the litigations documents and non-litigation documents and he has been associated with us since conception and has immensely contributed to our firm’s growth.",
      desig: "Founder",
    },
    flag: 1,
  },
  {
    id: "paresh-bhoir",
    desn:"ADV.",
    title: " PARESH BHOIR",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "PARESH BHOIR",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "swapnil-irpanwar",
    desn:"ADV.",
    title: "SWAPNIL IRPANWAR",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "SWAPNIL IRPANWAR",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "amit-dubey",
    desn:"ADV.",
    title: "AMIT DUBEY",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "AMIT DUBEY",
      subtitle:
      "",
    body1:
      "Amit Dubey is a practicing advocate, law enthusiast and aspiring writer. He has also shown a keen interest in social issues affecting different classes of society. Amit Dubey has completed his B.L.S. LL.B from University of Mumbai in the year 2014. He is practicing before the Hon’ble Bombay High Court and various District Courts and Tribunals across Maharashtra. He is associated with M/s. Law Counsellors,advocates &amp; Solicitors, a Law Firm based in Mumbai.",
    body2:
      "Amit Dubey’s expertise includes:",
    body3: "Criminal law , Litigation & Alternate Dispute Resolution , Real Estate and Land Laws , Due Diligence and Title Searches , Competition law",
      desig: "Founder",
    },
    flag: 1,
  },
  {
    id: "uttam-dubey",
    desn:"ADV.",
    title: "UTTAM DUBEY",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "UTTAM DUBEY",
      subtitle:
        "All people are equal before the law. A good attorney is what makes a difference.",
      body1:
        "ADV. Uttam S. Dubey has completed his B.L.S. LL.B & LL.M from University of Mumbai. He is practicing before the Hon’ble Bombay High Court and various District Courts and Tribunals across Maharashtra. He is associated with M/s. Law Counsellors, advocates & Solicitors, a Law Firm based in Mumbai.",
      body2:
        "Since, the start of the practice Mr. Dubey has shown interest in providing pro-bono legal aid services to the people in need. In the year 2020 he started his YouTube channel by the name of “Law & U – Decoding Law for You” for spreading awareness regarding Legal reforms and Rights of the Citizen.",
      body3:
        "During his legal practice Mr. Uttam  Dubey has appeared for some of the biggest corporate houses and families and has reported judgments of Bombay High Courts to his credit.",
      desig: "Founder",
    },
    flag: 1,
  },
  // {
  //   id: "rani-tripathi",
  //   flag: 1,
  //   desn:"ADV.",
  //   title: "RANI TRIPATHI",
  //   desc: {
  //     title: "ADVOCATE/SOLICITOR",
  //     name: "RANI TRIPATHI",
  //     subtitle:
  //     "",
  //   body1:
  //     "",
  //   body2:
  //     "",
  //   body3: "",
  //     desig: "Founder",
  //   },
  // },
  {
    id: "mehz-shaikh",
    desn:"ADV.",
    title: "MEHZ SHEIKH",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "MEHZ SHEIKH",
      subtitle:
        "Mehz, a 2017 law graduate from D.Y. Patil University, Mumbai, joined us in January 2020. Having gained rich experience in contract management, she works closely with our clients to appropriately document their understanding in the form of contracts, agreements, legal notices, suits, complaints and other legal writings with powerful written advocacy.",
      body1:
       "Mehz, a 2017 law graduate from D.Y. Patil University and presently pursuing L.L.M in Business Law from S.N.D.T University, joined us in January 2020. Having gained rich experience in contract management, she works closely with our clients to appropriately document their understanding in the form of contracts, agreements, legal notices, suits, complaints and other legal writings with powerful written advocacy.",
      body2:
      "She has worked on a wide variety of cases including matters of corporate, business, Information Technology, IPR, consumer disputes, alternate dispute resolutions, corporate, civil, criminal and RERA matters. Being heavily involved in client interaction, gathering data, due diligence, extensive legal research and drafting, she focuses on complex non-litigatory matters in various fields of Law.",
      body3:
      " Prior to joining us, Mehz has accrued 11+ years of corporate experience in various industries like Technology, e-Commerce, FMCG, Airlines, Real Estate, Security Solutions & MNC BPO.",
      email:"mehz@lawcounsellors.in",
      desig: "Founder",
    },
    flag: 1,
  },
  {
    id: "ashish-gharat",
    desn:"ADV.",
    title: "ASHISH GHARAT",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "ASHISH GHARAT",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "alka-singh",
    desn:"ADV.",
    title: " ALKA SINGH",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "ALKA SINGH",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "rajuram-kuleriya",
    desn:"ADV.",
    title: "RAJURAM KULERIYA",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "RAJURAM KULERIYA",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "varay-dubey",
    desn:"ADV.",
    title: "VARAD DUBEY",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "VARAD DUBEY",
      subtitle:
      "",
    body1:
      "Varad is currently enrolled at Maharashtra Bar Council and practicing at Bombay High Court, Mumbai bench.",
    body2:
      "He appears before District Court of Greater Bombay, Panvel and Vashi along with Debt Recovery Tribunals, National Company Law Tribunal.",
    body3: "He has been actively involved in multiple matters regarding White Collar Crimes(PMLA), Arbitration, Mediaton etc.",
      desig: "Founder",
    },
    flag: 1,
  },
  {
    id: "ritika-gupta",
    desn:"ADV.",
    title: "RITIKA GUPTA",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "RITIKA GUPTA",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "suhas-kadu",
    desn:"ADV.",
    title: "SUHAS KADU",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "SUHAS KADU",
      subtitle:
      "",
    body1:
      "",
    body2:
      "",
    body3: "",
      desig: "Founder",
    },
    flag: 0,
  },
  {
    id: "nidhi-pandey",
    desn:"ADV.",
    title: "NIDHI PANDEY",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "NIDHI PANDEY",
      subtitle:
      "",
    body1:
      "Adv. Nidhi Pandey has completed her LL.B in the year 2021, from The University of Mumbai. She has appeared in various matter before the Hon’ble District & Session Courts, Tribunals such as NCLT & DRT Mumbai & Navi Mumbai. She is associated with us since 2021.",
    body2:
      "Since the start of her practice, Nidhi has gained rich exposure in Civil, Criminal and Corporate litigatory matters. During her legal practice she has appeared before, Hon’ble District Courts, Civil Judge Senior Division / Junior Division, JMFC, DRT, Addl. Chief Metropolitan Magistrate Court and Charity Commissioner.  ",
    body3: " She is skilled in drafting various legal documents such as Civil Suits, Criminal Complaint, Legal Notices and Agreements etc.",
      desig: "Founder",
      email:"ID-nidhipandey743@gmail.com",
    },
    flag: 1,
  },
  {
    id: "gayatri-patel",
    desn:"ADV.",
    title: "GAYATRI PATEL",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "GAYATRI PATEL",
      subtitle:
      "",
    body1:
      "With extensive field experience and determination to achieve positive results. Gayatri a 2020 Law graduate from Dr. DY Patil University (Bachelor of Legal Science/ LLB). Gayatri joined us in the year 2019. She is currently pursuing her LLM in Business Law. Gayatri is a well accomplished lawyer with strong attention to detail and accuracy, excellent critical thinking and research skills.",
    body2:
      "She is known for being approachable, smart and prompt in her response with a calm and practical approach. Her specialization is in Civil, Criminal, Rera, Family and Arbitration matters as well as non- litigation.",
    body3: "Gayatri has represented several clients in high stake civil matters and proceedings at the Hon’ble High Court of Bombay, Kalyan Court, Thane Court, City Civil Court, D.R.T, District Consumer Forum, Co-operative Courts and Family Courts.Intensive drafting of transaction documents i.e. Agreements, Legal Notices, Complaints, MOU, Power of Attorney, Due diligence of Property Title Certificate and Search Report, Sale deed, Mortgage Deed, Term Loan Agreement, Lease Deeds, Joint Venture Agreements, Indemnity Bond, Bank Guarantee/corporate Guarantee, Agreement of Lease, Leave and License, Affidavits, Undertakings etc.",
      email:"advgayatripatel@gmail.com",
      desig: "Founder",
    },
    flag: 1,
  },
  {
    id: "nikita-sharma",
    desn:"ADV.",
    title: "NIKITA SHARMA",
    desc: {
      title: "ADVOCATE/SOLICITOR",
      name: "NIKITA SHARMA",
      subtitle:
      "",
    body1:
      "Adv. Nikita Sharma is a 2018 law graduate from the University of Mumbai and also has a Master’s Degree in Forensic Science from the Institute of Forensic Science, Mumbai, to her credit. With an experience of more than 3 years, she joined us in the year 2021.",
    body2:
      "She has acquired immense knowledge and exposure in the field of Forensics and analysing of evidence in criminal matters. With her advanced drafting skills, Nikita has handled various non-litigatory matters such as drafting various legal documents like Civil Suits, Criminal Complaints, MOU, Sale Deed, Legal Notices, and various other Agreements, etc.",
    body3: "She has represented several of our clients in Civil as well as Criminal matters and proceedings before the Hon’ble High Court of Bombay, Session Courts of Mumbai, Thane and Panvel, DRT, District Consumer Forum and Co-operative Society Court.",
    email:"adv.nikitasharma229@gmail.com",  
    desig: "Founder",
    },
    flag: 1,
  },
];
