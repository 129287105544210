import React from 'react';
import { Grid, Typography, Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Property from '../../assets/sample/property.png';
import IntellectualProperty from '../../assets/sample/intellectualProperty.png';
import Conveyancing from '../../assets/sample/conveyancing.png';
import Startup from '../../assets/sample/startup.png';
import Bank from '../../assets/sample/bank.png';
import Litigation from '../../assets/sample/litigation.png';
import Arbitration from '../../assets/sample/arbitration.png';
import Mergers from '../../assets/sample/mergers.png';
import Criminal from '../../assets/sample/criminal.png';
import Corporate from '../../assets/sample/corporate.png';
import Will from '../../assets/sample/will.png';
import Tax from '../../assets/sample/tax.png';
import { motion } from 'framer-motion';
// const variants = {
//   initial: { opacity: 0 },
//   whileInView: { opacity: 1 },
//   transition: { duration: 5 },
// }
const useStyles = makeStyles((theme) => ({
  caseServices: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: '#f4f4f4',
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: 'unset',
      paddingRight: 'unset',
    }
  },
  caseServicesPreTitleWrapper: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(6),
    }
  },
  caseServicesPretitle: {
    fontFamily: 'Mukta',
    color: '#c29032',
    fontWeight: 400,
  },
  caseServicesTitle: {
    fontFamily: 'Playfair Display',
    color: 'rgb(0,0,0)',
    fontWeight: 500,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    }
  },
  caseServicesCard: {
    height:'100%',
    backgroundColor:'#fff',
    color: '#fff',
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    borderBottom: '3px solid #fff',
    boxShadow: '0 25px 75px rgb(0 0 0 / 5%)',
    cursor:'pointer',
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
    // '&:hover':{
    //   borderBottom: '3px solid #c29032',
    //   '& $caseServicesCardImg':{
    //     display:'none',
    //     transform: 'translateY(0)',
    //     transition: 'transform 1s, display 1s',
    //   },
    //   '& $caseServicesCardTitle':{
    //     display:'none',
    //     transform: 'translateY(0)',
    //     transition: 'transform 1s, display 1s',
    //   },
    //   '& $caseServicesCardBody':{
    //     display:'block',
    //     transform: 'translateY(0)',
    //     transition: 'transform 1s, display 1s',
    //   }
    // }
  },
  caseServicesCardImg: {
    width: 48,
    display: 'block',
    [theme.breakpoints.down("sm")]: {
      width: 40,
    },
    [theme.breakpoints.down("xs")]: {
      width: 32,
    }
  },
  caseServicesCardTitle: {
    fontFamily: 'Playfair Display',
    color: 'rgb(0,0,0)',
    fontWeight: 500,
    marginTop: theme.spacing(3),
    display:'block',
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1.5),
    }
  },
  hidden:{
    visibility:'hidden',
  },
  caseServicesCardBody: {
    fontFamily: 'Playfair Display',
    color: 'rgb(119,119,119)',
    fontWeight: 400,
    display:'none',
  },
}));

export default function TestCaseServices(props) {

  const classes = useStyles();

  return (
    <div className={classes.caseServices}>
      <Container>
        <Grid container className={classes.caseServicesPreTitleWrapper}>
          <Grid item xs={12}>
            <motion.div
              >
              <Typography variant="body1" className={classes.caseServicesPretitle} >PRACTICE AREAS</Typography>
              <Typography className={classes.caseServicesTitle} variant="h5" component="h2">Law Services</Typography>
            </motion.div>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Criminal} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Criminal Defense<span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Conveyancing} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Conveyancing <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}
          >
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Startup} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Startups and Company formations</Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={IntellectualProperty} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Intellectual Property <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Bank} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Banking and Finance <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Litigation} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Litigation <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Arbitration} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Arbitration & Alternate Dispute Resolution</Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Mergers} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Mergers & Acquisitions and Private Equity</Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Corporate} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Corporate Law <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Will} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Wills, Probate and Sucessions</Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Property} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Property <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <motion.div className={classes.caseServicesCard}
              >
              <img alt="text" src={Tax} className={classes.caseServicesCardImg} ></img>
              <Typography className={classes.caseServicesCardTitle} variant="subtitle1">Direct & Indirect Tax <span className={classes.hidden}> text text text</span></Typography>
              {/* <Typography className={classes.caseServicesCardBody} variant="subtitle2">Quisque nec iaculis nibh suspendisse the tempus orci enim maximus quis.</Typography> */}
            </motion.div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
