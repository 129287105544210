import React from 'react';
import SampleContactUsForm from './SampleContactForm';
import SampleContactHero from './SampleContactHero';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    contact: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
        },
    }
}));
export default function SampleContact(props) {
    const classes = useStyles();
    // const theme = useTheme();
    return (
        <div className={classes.contact}>
            <SampleContactHero />
            <SampleContactUsForm />
        </div>
    );
}