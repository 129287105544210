import React from 'react';
import SampleCareersHero from './SampleCareersHero';
import SampleCareersForm from './SampleCareersForm';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    careers: {
        padding: theme.spacing(5),
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(3),
        },
        [theme.breakpoints.down('xs')]: {
            padding: theme.spacing(0),
        },
    }
}));

export default function TestCareers(props) {
    const classes = useStyles();
    // const theme = useTheme();
    return (
        <div className={classes.careers}>
            <SampleCareersHero />
            <SampleCareersForm />
        </div>
    );
}