/** @format */

import { Button, Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import TestHomeOne from "../../assets/newImages/testHomeOne.jpeg";

const useStyles = makeStyles((theme) => ({
  testHero: {
    background: `url(${TestHomeOne})`,
    position: "relative",
    backgroundAttachment: "fixed",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
    [theme.breakpoints.down("sm")]: {
      height: "88vh",
    },
    [theme.breakpoints.down("xs")]: {
      height: "80vh",
    },
  },
  backgroundOverlay: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "flex-start",
    position: "absolute",
    height: "100%",
    width: "100%",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background: "rgba(0, 0, 0, 0.5)",
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      height: "88vh",
      padding: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      height: "80vh",
      padding: theme.spacing(2),
    },
  },
  // testHeroWrapper: {
  //     // display: 'flex',
  //     // flexDirection: 'column',
  //     // justifyContent: 'flex-end',
  //     // alignItems: 'flex-start',
  // },
  testHeroCard: {
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },
  testHeroCardTitle: {
    color: "rgb(0,0,0)",
    fontFamily: "Playfair Display",
    fontWeight: 500,
  },
  testHeroCardSubtitle: {
    color: "rgb(119,119,119)",
    fontWeight: 400,
    fontFamily: "Mukta",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  testHeroCardButton: {
    color: "#fff",
    backgroundColor: "#c29032",
    fontSize: 12,
    borderRadius: "50px",
    fontWeight: 400,
    letterSpacing: 3,
    padding: theme.spacing(2, 3, 2, 3),
    "&:hover": {
      color: "#fff",
      backgroundColor: "#000",
    },
  },
}));

export default function TestHero(props) {
  const classes = useStyles();
  const navigate = useNavigate();
  return (
    <motion.div
      className={classes.testHero}>
      <div className={classes.backgroundOverlay}>
        <Container>
          <Grid container>
            <Grid container xs={12}>
              <motion.div
                className={classes.testHeroCard}>
                <Typography variant="h5" className={classes.testHeroCardTitle}>
                  Law Counsellors
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.testHeroCardSubtitle}>
                  Send us your message
                </Typography>
                <Button
                  variant="contained"
                  className={classes.testHeroCardButton}
                  onClick={() => {
                    navigate("/Contact");
                  }}>
                  Message
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </motion.div>
  );
}
