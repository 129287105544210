import React from 'react';
import { Grid, Typography, Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import AboutUsSample from '../../assets/newImages/manWalking.jpg';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
    aboutUsHero: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        paddingLeft:theme.spacing(5),
        paddingRight:theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(9),
            marginBottom: theme.spacing(9),
            paddingLeft:theme.spacing(4),
            paddingRight:theme.spacing(4),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
            paddingLeft:theme.spacing(0),
            paddingRight:theme.spacing(0),
        },
    },
    aboutUsPretitle: {
        fontFamily:'Mukta',
        color: '#c29032',
        fontWeight: 400,
    },
    aboutUsTitle: {
        fontFamily:'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
        },
    },
    aboutUsHeroImg: {
        width: '100%',
        display: 'block',
    },
    aboutUsSubtitle: {
        fontFamily:'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1.5),
        },
    },
    aboutUsBody: {
        fontFamily:'Mukta',
        color: '#777',
        fontWeight: 400,
        // marginBottom: theme.spacing(2),
        // [theme.breakpoints.down("sm")]: {
        //     marginBottom: theme.spacing(1.5),
        // },
        // [theme.breakpoints.down("xs")]: {
        //     marginBottom: theme.spacing(1),
        // },
    },
}));

export default function TestAboutUs(props) {

    const classes = useStyles();

    return (
        <div className={classes.aboutUsHero}>
            <Container >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                    <motion.div
                            >
                        <Typography variant="body1" className={classes.aboutUsPretitle}>What We Believe</Typography>
                        <Typography variant="h2" component="h1" className={classes.aboutUsTitle}>About Us</Typography>
                      </motion.div>
                    </Grid>
                    <Grid item xs={12} sm={12} md="true">
                        <motion.div
                            >
                        <Typography variant="subtitle2" className={classes.aboutUsBody}>Law Counsellors is a  full-service law firm with a strong focus on advising clients (individuals or corporations) about their legal rights and responsibilities, and to represent clients in civil or criminal cases, business transactions, and other matters in which legal advice and other assistance are sought. Our practical knowledge and rich experiences allow us to provide comprehensive legal services to our clients.
                        <br/>
                        <br/>
                        Since its founding, Law Counsellors has always believed in the highest level of integrity, followed a people-oriented approach, and given back to the society whenever possible. We continue to follow the principle of “client first” in providing reliable and highly effective services
                        <br/>
                        <br/>
                        All of Law Counsellors’s attorneys, CPAs, and tax professionals are educated in prestigious institutions in India and abroad.</Typography>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4}>
                        <motion.div
                            >
                        <img src={AboutUsSample} alt="about us" className={classes.aboutUsHeroImg}></img>
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}