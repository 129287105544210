/** @format */

import emailjs from "@emailjs/browser";
import {
  Button,
  Container,
  FormControlLabel,
  Grid,
  Snackbar,
  TextField,
  Typography,
} from "@material-ui/core";
import Checkbox from "@material-ui/core/Checkbox";
import { makeStyles } from "@material-ui/core/styles";
import SendIcon from "@material-ui/icons/Send";
import MuiAlert from "@material-ui/lab/Alert";
import { motion } from "framer-motion";
import React, { useState } from "react";
import {
  EMAILJS_CAREERS_TEMPLATE_ID,
  EMAILJS_PUBLIC_KEY,
  EMAILJS_SERVICE_ID,
} from "../../constants";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles((theme) => ({
  sampleCareersForm: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(8),
    },
  },
  careersDescriptionTitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },
  careersDescriptionBody: {
    fontFamily: "Mukta",
    color: "#777",
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  careersFormTitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  careersFormBody: {
    color: "#636b81",
  },
  formControl: {
    width: "100%",
  },
  selectEmpty: {},
  uploadButton: {
    color: "#fff",
    backgroundColor: "#c29032",
    fontSize: 12,
    borderRadius: "50px",
    fontWeight: 400,
    letterSpacing: 3,
    padding: theme.spacing(2, 3, 2, 3),
    "&:hover": {
      color: "#fff",
      backgroundColor: "#000",
    },
  },
}));

export default function SampleCareersForm(props) {
  const [state, setState] = React.useState({
    checkedA: true,
  });

  const handleChangeCheckBox = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
  };
  const classes = useStyles();

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [verify, setVerify] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    if (!firstName || !lastName || !email | !message) {
      setVerify(true);
      return;
    }

    const afterCareerMail = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_CAREERS_TEMPLATE_ID,
      {
        firstName,
        lastName,
        email,
        message,
      },
      EMAILJS_PUBLIC_KEY
    );

    console.log(afterCareerMail);
    setSuccess(true);

    setFirstName("");
    setLastName("");
    setEmail("");
    setMessage("");
  };

  return (
    <Container className={classes.sampleCareersForm}>
      <Grid container spacing={4}>
        <Grid item xs={12} sm={12} md={6}>
          <motion.div>
            <Typography
              variant="h2"
              className={classes.careersDescriptionTitle}>
              Internship
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              Law Counsellors offers law school students a truly unique
              experience. We’re a mid-size, Mumbai-based firm with a national
              presence – large enough to attract the biggest, first-rate clients
              – yet small enough to maintain a collegial work environment.
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              Law Counsellors is currently encouraging third-year to final-year
              law students to apply for our twelve to twenty-four-week associate
              program in our Navi Mumbai office. Our program provides
              substantive, real-world legal work assignments for its
              participants. Participants will be able to take on a wide range of
              projects across our Corporate, Intellectual Property and
              Litigation groups, allowing for a personalized legal experience.
            </Typography>
          </motion.div>
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <motion.div
            >
            <Typography
              variant="h2"
              className={classes.careersDescriptionTitle}>
              Entry Level
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              We respect the individual and value team players. We seek out and
              hire lawyers with diverse backgrounds and unique personal
              experiences. We believe that diverse perspectives make us
              highly-creative problem solvers and a stronger team. And at Law
              Counsellors, you succeed by making the rest of your team better,
              as our firm culture emphasizes cooperation among team members.
              Each team member is expected to perform excellent work—not solely
              for his or her own greater glory—but for the greater success of
              the firm and our clients.
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              Our Lawyers work hard and have fun doing it, but don’t need to fit
              into any personality mold to succeed. Our firm’s perspective
              mirrors that of the entrepreneurs, engineers, and scientists who
              populate the rapid-growth companies. We have a passion for
              excellence and we value results, not conformity. Law firms
              definitely have personalities, and we very much value ours.
            </Typography>
          </motion.div>
        </Grid>
        {/* <Grid item xs={12} sm={12} md={6}>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              Applicants should submit the following:
              <ul>
                <li> Cover letter</li>
                <li> Resume </li>
                <li> Law School Reference/ Mark-sheet.</li>
                <li> Writing sample (five to ten pages)</li>
              </ul>
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              Application Deadline:
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              There are no deadlines for internship as we engage students based
              on vacancy. If we are unable to engage you at present, we shall
              intimate you as and when positions are vacant.
            </Typography>
          </motion.div>
          <motion.div
            >
            <Typography
              variant="subtitle2"
              className={classes.careersDescriptionBody}>
              Please email application package to: recruit@lawcounsellors.in
            </Typography>
          </motion.div>
          <motion.div
            ></motion.div>
          <Typography
            variant="subtitle2"
            className={classes.careersDescriptionBody}>
            You can also upload your Resume in the form below this page:
          </Typography>
        </Grid> */}
        <Grid item xs={12} sm={12} md={6}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <motion.div
                >
                <Typography variant="h2" className={classes.careersFormTitle}>
                  Send us your Message below
                </Typography>
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <motion.div>
                <TextField
                  placeholder="First Name"
                  variant="outlined"
                  fullWidth
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <motion.div>
                <TextField
                  placeholder="Last Name"
                  variant="outlined"
                  fullWidth
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div>
                <TextField
                  placeholder="Email"
                  variant="outlined"
                  fullWidth
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div>
                <TextField
                  multiline
                  rows={4}
                  placeholder="Your Message"
                  variant="outlined"
                  fullWidth
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={state.checkedA}
                      onChange={handleChangeCheckBox}
                      name="checkedA"
                    />
                  }
                  label="I accept the terms and condition"
                />
              </motion.div>
            </Grid>
            <Grid item xs={12}>
              <motion.div>
                <Button
                  variant="contained"
                  className={classes.uploadButton}
                  endIcon={<SendIcon />}
                  size="large"
                  color="primary"
                  onClick={handleSubmit}
                  disableElevation>
                  Send My Application
                </Button>
              </motion.div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar
        open={verify}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setVerify(false)}>
        <Alert
          onClose={() => setVerify(false)}
          severity="info"
          sx={{ width: "100%" }}>
          Please fill all the details in the form to submit
        </Alert>
      </Snackbar>
      <Snackbar
        open={success}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setSuccess(false)}>
        <Alert
          onClose={() => setSuccess(false)}
          severity="success"
          sx={{ width: "100%" }}>
          Your Form has been submitted!
        </Alert>
      </Snackbar>
    </Container>
  );
}
