import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import OverviewSample from '../../assets/newImages/buildings.jpg';
import { motion } from 'framer-motion';

const useStyles = makeStyles((theme) => ({
    overviewHero: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        paddingLeft:theme.spacing(5),
        paddingRight:theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(9),
            marginBottom: theme.spacing(9),
            paddingLeft:theme.spacing(4),
            paddingRight:theme.spacing(4),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
            paddingLeft:theme.spacing(0),
            paddingRight:theme.spacing(0),
        },
    },
    overviewPretitle: {
        fontFamily: 'Mukta',
        color: '#c29032',
        fontWeight: 400,
    },
    overviewTitle: {
        fontFamily: 'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
        },
    },
    overviewHeroImg: {
        width: '100%',
        maxHeight: '500px',
        display: 'block',
    },
    overviewSubtitle: {
        fontFamily: 'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1.5),
        },
    },
    overviewBody: {
        fontFamily: 'Mukta',
        color: '#777',
        fontWeight: 400,
        // marginBottom: theme.spacing(2),
        // [theme.breakpoints.down("sm")]: {
        //     marginBottom: theme.spacing(1.5),
        // },
        // [theme.breakpoints.down("xs")]: {
        //     marginBottom: theme.spacing(1),
        // },
    },
}));

export default function TestOverview(props) {

    const classes = useStyles();

    return (
        <div className={classes.overviewHero}>
            <Container >
                <Grid container alignItems='center' spacing={4}>
                    {/* <Grid item xs={12}>
                        <motion.div
                            >
                            <Typography variant="body1" className={classes.overviewPretitle}>WE ARE UNIQUE</Typography>
                            <Typography variant="h2" component="h1" className={classes.overviewTitle}>Why Choose Us ?</Typography>
                        </motion.div>
                    </Grid> */}
                    <Grid item xs={12} sm={12} md={5}>
                        <motion.div
                            >
                        <img src={OverviewSample} alt="overview" className={classes.overviewHeroImg}></img>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} sm={12} md={7}>
                        <motion.div
                          
                            >
                            <Typography variant="h2" className={classes.overviewSubtitle}>Overview</Typography>
                        </motion.div>
                        <motion.div
                            >
                            <Typography variant="subtitle2" className={classes.overviewBody}>Law Counsellors has helped some of the India’s most recognized entities become, and remain, market leaders. From start-ups to large corporations, our clients are market leaders in the construction, technology and clean-tech sectors and are fundamentally changing the world through rapid innovation.
                            <br/>
                            <br/>
                            Having performed alongside such progressive and pioneering corporations for so long, our culture has come to reflect the entrepreneurial spirit of our client base. Unlike traditional law firms, we move at our clients’ speed and have earned the reputation as a go-to law firm for growing companies that need insightful, strategic counsel to help tackle the challenging issues that arise when the law cannot keep up with their pace of innovation.
                            <br/>
                            <br/>
                            Like many of our clients, Law Counsellors proves that sophisticated, cutting-edge work performed at the highest level isn’t always a function of size. Our goal is not to be the biggest law firm, but rather the best law firm in the practice areas critical to our clients. How do we do it? Keeping our focus on what is important to our clients instead of trying to be a legal superstore.
                            </Typography>
                        </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}