import React from 'react';
// import TestCaseStudies from './TestCaseStudies';
import TestServices from './TestServices';
import TestHero from './TestHero';
// import TestClientReviews from './TestClientReviews';
import TestOverview from './TestOverview';
import TestAboutUs from './TestAboutUs';
import TestPhilosophy from './TestPhilosophy';
export default function testHome(props) {

    return (
        <div>
            <TestHero />
            <TestOverview />
            <TestServices />
            <TestAboutUs />
            {/* <TestClientReviews /> */}
            {/* <TestCaseStudies /> */}
            <TestPhilosophy />
        </div>
    );
}