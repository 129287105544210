import React from 'react';
import { Grid, Typography, Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    privacyPolicyHero: {
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(9),
            marginBottom: theme.spacing(9),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(8),
            marginBottom: theme.spacing(8),
        },
    },
    privacyPolicyPretitle: {
        color: '#c29032',
        fontWeight: 400,
    },
    privacyPolicyTitle: {
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
        },
    },
    privacyPolicyBody: {
        color: '#777',
        fontWeight: 400,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(1.5),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1),
        },
    },
}));

export default function PrivacyPolicy(props) {

    const classes = useStyles();

    return (
        <div className={classes.privacyPolicyHero}>
            <Container >
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography className={classes.privacyPolicyPretitle}>ADVOCATE/SOLICITOR</Typography>
                        <Typography variant="h2" component="h1" className={classes.privacyPolicyTitle}>Privacy Policy</Typography>
                        <Typography variant="h4" className={classes.privacyPolicySubtitle}>Terms of general site usage</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="body1" className={classes.privacyPolicyBody}>Khaitan & Co and its affiliates (collectively, "Khaitan") is committed to protecting your privacy and has provided this policy ("Policy") to familiarize you with the manner in which it collects, uses, shares and discloses your information that is collected through https://www.khaitanco.com/ and associated mobile applications (the "Website").</Typography>
                        <Typography variant="body1" className={classes.privacyPolicyBody}>
                        The terms of the Policy provided herein govern your use of the Website and any content provided, accessed or distributed in the Website. For our client confidentiality obligations and associated references, please refer to our terms of engagement.
This Policy shall be construed to be provided in compliance with the Information Technology Act 2000 and the rules framed thereunder (as amended from time to time) ("IT Act"). The words and expressions used in this Policy but not defined herein will have the meaning assigned to them under the IT Act.
During the use of the Website we may collect the following types of information ("Personal Information"):
contact information, such as your name, job title, postal address, including your home address, business address, telephone number, mobile phone number, fax number and email address;
further business information necessarily processed in a project or client contractual relationship with Khaitan or voluntarily provided by you, such as instructions given, payments made, requests and projects;
your password for the Website or other password protected platforms or services, where you have one;
information collected from publicly available resources, integrity data bases and credit agencies;
information about relevant and significant litigation or other legal proceedings against you or a third party related to you and interaction with you which may be relevant for antitrust purposes, if legally required for compliance purposes;
information about your health for the purpose of identifying and being mindful of any disabilities or special dietary requirements you may have, which we may ask in connection with the registration for and provision of access to an event or seminar. Any use of such information is based on your consent. If you do not provide such information, we will not be able to take relevant precautions;
information about your experience, qualifications, desired position within Khaitan, which you may provide in connection with any application made to Khaitan. Any use of such information is based on your consent;
other personal data regarding your preferences notwithstanding its relevance to the legal services that we provide; and/or
details of your visits to our premises.
From time to time, it may include personal data about your membership of a professional or trade association or union, personal health data, details of dietary preferences when relevant for events to which we invite you and details of any criminal record you may have.
Your Personal Information will be kept confidential to the maximum possible extent and will be used to support your relationship with Khaitan. Any Sensitive Personal Information will be kept confidential as required under applicable Indian laws.
Any comments, messages, blogs, scribbles etc posted / uploaded / conveyed / communicated by users on the public sections of the Website becomes published content and is not considered personal information subject to this Policy.
We may collect personal data about you in a number of circumstances, including
when you or your organisation browse, make an enquiry or otherwise interact on the Website;
when you attend a seminar or another Khaitan event or sign up to receive personal data from us, including for training purposes; or
when you or your organisation offer to provide services to us.
In some circumstances, we collect personal data about you from a third party source, including, personal data from your organisation, other organisations with whom you have dealings, inter alia, government agencies, credit reporting agencies, information or service providers or from publicly available records.
As a general principle, you will provide us with your personal data entirely voluntarily. There are generally no detrimental effects for you if you choose not to consent or to provide personal data.
Khaitan does not sell or rent Personal Information. We may use your Personal  Information for the following purposes only ("Permitted Purposes"):
to verify whether you are entitled to access and use the Website and the services made available through the Website. This Personal Information may also be used to enable Khaitan to enhance your experience of the Website.
to provide legal advice or other services or things you may have requested, including online or legal technology services or solutions as instructed or requested by you or your organisation;
to manage and administer your or your organisation's business relationship with Khaitan, including processing payments, accounting, auditing, billing and collection and support services;
to comply with our legal obligations (such as record keeping obligations), compliance screening or recording obligations (including obligations under antitrust laws, export laws, trade sanction and embargo laws and for anti-money laundering, financial and credit check and fraud and crime prevention and detection purposes), which may include automated checks of your contact data or other information you provide about your identity against applicable sanctioned-party lists and may contact you to confirm your identity in case of a potential match or recording interaction with you which may be relevant for compliance purposes;
to analyse and improve our services and communications with you;
to protect the security of and manage access to our premises, information technology and communication systems, online platforms, websites and other systems and to prevent and detect security threats, fraud or other criminal or malicious activities;
for insurance purposes;
to monitor and assess compliance with our policies and standards;
to identify persons authorised to trade on behalf of our clients, customers, suppliers and/or service providers;
to comply with our legal and regulatory obligations and requests anywhere in the world, including reporting to and/or being audited by national and international regulatory bodies;
to enable vendors and service providers to provide various services subscribed to by you including contact information verification, payment processing, customer service, website hosting, data analysis, infrastructure provision, information technology services, and other similar services, Khaitan may disclose your Personal Information to its affiliates, group companies, consultants, vendors and contractors under confidentiality restrictions;
to comply with court orders and exercise and/or defend Khaitan's legal rights; and
for any purpose related and/or ancillary to any of the above or any other purpose for which your Personal Information was provided to us.
Where you have expressly given us your consent, we may process your Personal Information also for the following purposes:
communicating with you through the channels you have approved to keep you up to date on the latest legal developments, announcements, and other information about Khaitan services, products and technologies (including client briefings, newsletters and other information) as well as Khaitan events and projects;
informing you of customer surveys, marketing campaigns, market analysis, sweepstakes, contests or other promotional activities or events; or
collecting information about your preferences to create a user profile to personalise and foster the quality of our communication and interaction with you (for example, by way of newsletter tracking or website analytics).
With regard to marketing related communication, we will, where legally required, only provide you with such information after you have opted in and provide you the opportunity to opt out anytime if you do not want to receive further marketing related communication from us. We will not use your Personal Information for taking any automated decisions affecting you or creating profiles other than as described above.
We may process your personal data, depending on the Permitted Purpose we use your personal data for, on one or more of the following legal grounds:
to perform and carry our client instructions since processing is necessary for the performance of client instructions or other contract with you or your organisation;
to comply with our legal obligations (eg to keep pension records or records for tax purposes); or
for the purposes of our legitimate interest or those of any third party recipients that receive your personal data, provided that such interests are not overridden by your interests or fundamental rights and freedoms.
In addition, the processing may be based on your consent where you have expressly given that to us.
We may share your Personal Information in the following circumstances:
we may share your Personal Information between the other Khaitan entities on a confidential basis where required for the purpose of providing legal advice or other products or services and for administrative, billing and other business purposes;
If we have collected your Personal Information in the course of providing legal services to any of our clients, we may disclose it to that client, and where permitted by law to others for the purpose of providing those services;
we may disclose your contact details on a confidential basis to third parties for the purposes of collecting your feedback on the firm’s service provision, to help us measure our performance and to improve and promote our services;
we may share your Personal Information with companies providing services for money laundering checks, credit risk reduction and other fraud and crime prevention purposes and companies providing similar services, including financial institutions, credit reference agencies and regulatory bodies with whom such Personal Information is shared;
we may share your Personal Information with any third party to whom we assign or novate any of our rights or obligations;
we may share your Personal Information with courts, law enforcement authorities, regulators or attorneys or other parties where it is reasonably necessary for Khaitan to exercise or defend a legal or equitable claim, or for the purposes of a confidential alternative dispute resolution process;
we may also instruct service providers within or outside Khaitan, domestically or abroad, eg shared service centres, to process personal data for Permitted Purposes on our behalf and in accordance with our instructions only. Khaitan will retain control over and will remain fully responsible for your Personal Information and will use appropriate safeguards as required by applicable law to ensure the integrity and security of your Personal Information when engaging such service providers; and
we may also use aggregated personal data and statistics for the purpose of monitoring website usage in order to help us develop our Website and our services.
Otherwise, we will only disclose your Personal Information when you direct us or give us permission, when we are required by applicable law or regulations or judicial or official request to do so, or as required to investigate actual or suspected fraudulent or criminal activities.
If you provide Personal Information to us about someone else (such as one of your directors or employees, or someone with whom you have business dealings) you must ensure that you are entitled to disclose that Personal Information to us and that, without our taking any further steps, we may collect, use and disclose that Personal Information as described in this Policy. In particular, you must ensure the individual concerned is aware of the various matters detailed in this Policy, as those matters relate to that individual, including our identity, how to contact us, our purposes of collection, our personal data disclosure practices (including disclosure to overseas recipients), the individual's right to obtain access to the Personal Information and make complaints about the handling of the Personal Information, and the consequences if the Personal Information is not provided (such as our inability to provide services).
We will take appropriate technical and organisational measures to keep your Personal Information confidential and secure in accordance with our internal procedures covering the storage, disclosure of and access to Personal Information. Personal Information may be kept on our personal data technology systems, those of our contractors or in paper files.
Khaitan uses reasonable security measures, at the minimum those mandated under the IT Act and read with Information Technology (Reasonable Security Practices and Procedures and Sensitive Personal Data or Information) Rules 2011, to safeguard and protect your Personal Information and Sensitive Personal Information. Khaitan implements such measures, as stated above, to protect against unauthorized access to, and unlawful interception of, Personal Information and Sensitive Personal Information. Additionally, we have adopted measures to ensure that your Personal Information and Sensitive Personal Information is accessible to our employees or partners’ employees strictly on a need to know basis. You accept the inherent security implications of providing information over internet / cellular / data networks and will not hold Khaitan responsible for any breach of security or the disclosure of Personal Information unless Khaitan has been grossly and willfully negligent.
Your Personal Information will primarily be stored in an electronic form. However, certain data can also be stored in physical form. Khaitan may store, collect, process and use your Personal Information in countries other than the Republic of India but under full compliance with applicable laws. Khaitan may enter into an agreement with third parties (in or outside of India) to store collect, process your Personal Information and Sensitive Personal Information under full compliance with applicable laws.
Notwithstanding anything contained in this Policy or elsewhere, Khaitan shall not be held responsible for any loss, damage or misuse of your Personal Information, if such loss, damage or misuse is attributable to a Force Majeure Event. A "Force Majeure Event" shall mean any event that is beyond the reasonable control of Khaitan and shall include, inter alia, without limitation, sabotage, fire, flood, explosion, acts of God, civil commotion, strikes or industrial action of any kind, riots, insurrection, war, acts of government, computer hacking, unauthorized access to computer data and storage device, computer crashes, breach of security and encryption.
This Policy is subject to modification based on changes in the business, legal and regulatory requirements and will be made available to you online. We will make all reasonable efforts to communicate to you all significant changes to this Policy. You are encouraged to periodically visit this page to review the Policy and any changes thereto. In case you discontinue the use of the Website, it will not affect the applicability of the Policy to your prior uses of the Website.
We strive hard to keep our records updated and accurate with your latest information. You shall be responsible to ensure that the information or data you provide from time to time is and shall be correct, current and updated and you have all the rights, permissions and consent to provide such information or data to us.
Based on technical feasibility and requirements of applicable laws, we will provide you with the right to review, access and modify the Personal Information or Sensitive Personal Information that we maintain about you. We may perform verification before providing you access to this Personal Information. However, we are not responsible for the authenticity of the Sensitive Personal Information provided by you.
Further, you have the right to withdraw the consent provided to us in relation to your Sensitive Personal Information at any time in writing by sending an e-mail to us at compliance@khaitanco.com, in accordance with the terms of this Policy. However, please note that withdrawal of consent will not be retrospective in nature and shall be applicable prospectively. In case you do not provide your information or consent for usage of Sensitive Personal Information or subsequently withdraw your consent for usage of the Sensitive Personal Information so collected, Khaitan reserves the right to discontinue the services for which the said Sensitive Personal Information was sought.
If you wish to delete the Sensitive Personal Information provided to Khaitan, you can always do so by sending a request to us at compliance@khaitanco.com
You may note that correction or deletion of certain information or data may lead to cancellation of your registration with the Website or your access to certain features of the Website. You also agree and acknowledge that certain data or information cannot be corrected or deleted and/or is prohibited from being deleted under any applicable law or in lieu of law enforcement requests or under any judicial proceedings.
We are committed to protect your Personal Information collected and processed by us and look forward to your continued support for the same. In case of any feedback or concern regarding protection of your Personal Information, or any privacy related feedback or concerns you may contact us at compliance@khaitanco.com
We welcome your views about our Website and our Policy. If you would like to contact us with any queries or comments, please send an email to compliance@khaitanco.com or send a letter to Khaitan & Co, One Indiabulls Centre, 13th Floor, Tower 1, 841 Senapati Bapat Marg, Mumbai 400013, India.
                        </Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}