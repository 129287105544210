import React from 'react';
import { Grid, Container,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import SampleAkhileshSir from '../../assets/images/sampleAkhileshSir.jpg';
// import { motion } from 'framer-motion';
// import GoogleMapLawCounsellors from './../../assets/sample/googleMapLawCounsellors.png';
// import loadVariants from '../../variants';

// const variants = loadVariants();

const useStyles = makeStyles((theme) => ({
    sampleContactUs: {
        marginTop: theme.spacing(5),
        // marginBottom: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(4),
            // marginBottom: theme.spacing(4),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(8),
            // marginBottom: theme.spacing(3),
        },
    },
    sampleContactUsPretitle: {
        fontFamily:'Mukta',
        color: '#c29032',
        fontWeight: 400,
    },
    sampleContactUsTitle: {
        fontFamily:'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginTop: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
        },
    },
    googleMap:{
        width:'100%',
        display:'block',
        marginTop: theme.spacing(10),
        marginBottom: theme.spacing(10),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(5),
            marginBottom: theme.spacing(5),
        },
    },

}));

export default function SampleContactHero(props) {

    const classes = useStyles();

    return (
        
            <Container >
                <Grid container className={classes.sampleContactUs}>
                    {/* <Grid item xs={12}>
                        <motion.div
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: true }}
                            transition={{ duration: 1, delay: 1, }}
                            variants={{
                                visible: { opacity: 1,  },
                                hidden: { opacity: 0, }
                            }}>
                        <Typography variant='body2' className={classes.sampleContactUsPretitle}>GET IN TOUCH</Typography>
                        <Typography variant="h1" component="h1" className={classes.sampleContactUsTitle}>Contact Us</Typography>
                        </motion.div>
                    </Grid> */}
                    {/* <Grid item xs={12}>
                        <motion.div
                            initial='hidden'
                            whileInView='visible'
                            viewport={{ once: true }}
                            transition={{ duration: 1,delay:1 }}
                            variants={{
                              visible: { opacity: 1, translateX: 0, translateY: 0, translateZ: 0, },
                              hidden: { opacity: 0.1, translateX: 0, translateY: 0, translateZ: -50, }
                            }}>
                        <img alt="text" src={GoogleMapLawCounsellors} className={classes.googleMap}></img>
                        </motion.div>
                    </Grid> */}
                </Grid>
            </Container>
    );
}