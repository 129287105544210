/** @format */

import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const defaultTheme = createTheme({});

const { breakpoints } = defaultTheme;

const theme = createTheme({
  palette: {
    primary: {
      main: "#c29032",
    },
    secondary: {
      main: "#282e3f",
    },
  },
  typography: {
    fontFamily: ["Mukta", "Playfair Display"].join(","),
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          // fontFamily: 'Mukta', 'Playfair Display',
          backgroundColor: "unset",
        },
        h1: {
          fontSize: 48,
          fontWeight: "unset",
          margin: 0,
          [breakpoints.down("sm")]: {
            fontSize: 44,
          },
          [breakpoints.down("xs")]: {
            fontSize: 40,
          },
        },
        h2: {
          fontSize: 44,
          fontWeight: "unset",
          margin: 0,
          [breakpoints.down("sm")]: {
            fontSize: 40,
          },
          [breakpoints.down("xs")]: {
            fontSize: 36,
          },
        },
        h3: {
          fontSize: 40,
          fontWeight: "unset",
          margin: 0,
          [breakpoints.down("sm")]: {
            fontSize: 36,
          },
          [breakpoints.down("xs")]: {
            fontSize: 32,
          },
        },
        h4: {
          fontSize: 36,
          fontWeight: "unset",
          margin: 0,
          [breakpoints.down("sm")]: {
            fontSize: 32,
          },
          [breakpoints.down("xs")]: {
            fontSize: 28,
          },
        },
        h5: {
          fontSize: 32,
          fontWeight: "unset",
          margin: 0,
          [breakpoints.down("sm")]: {
            fontSize: 28,
          },
          [breakpoints.down("xs")]: {
            fontSize: 24,
          },
        },
        h6: {
          fontSize: 28,
          fontWeight: "unset",
          margin: 0,
          [breakpoints.down("sm")]: {
            fontSize: 24,
          },
          [breakpoints.down("xs")]: {
            fontSize: 20,
          },
        },
        p: {
          fontSize: 16,
          fontWeight: "unset",
          [breakpoints.down("sm")]: {
            fontSize: 15,
          },
          [breakpoints.down("xs")]: {
            fontSize: 14,
          },
        },
        ul: {
          fontSize: 18,
          fontWeight: "unset",
          [breakpoints.down("sm")]: {
            fontSize: 17,
          },
          [breakpoints.down("xs")]: {
            fontSize: 16,
          },
        },
      },
    },
    MuiTypography: {
      h1: {
        fontSize: 48,
        fontWeight: "unset",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: 44,
        },
        [breakpoints.down("xs")]: {
          fontSize: 40,
        },
      },
      h2: {
        fontSize: 44,
        fontWeight: "unset",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: 40,
        },
        [breakpoints.down("xs")]: {
          fontSize: 36,
        },
      },
      h3: {
        fontSize: 40,
        fontWeight: "unset",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: 36,
        },
        [breakpoints.down("xs")]: {
          fontSize: 32,
        },
      },
      h4: {
        fontSize: 36,
        fontWeight: "unset",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: 32,
        },
        [breakpoints.down("xs")]: {
          fontSize: 28,
        },
      },
      h5: {
        fontSize: 32,
        fontWeight: "unset",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: 28,
        },
        [breakpoints.down("xs")]: {
          fontSize: 24,
        },
      },
      h6: {
        fontSize: 28,
        fontWeight: "unset",
        margin: 0,
        [breakpoints.down("sm")]: {
          fontSize: 24,
        },
        [breakpoints.down("xs")]: {
          fontSize: 20,
        },
      },
      subtitle1: {
        fontSize: 20,
        fontWeight: "unset",
        [breakpoints.down("sm")]: {
          fontSize: 18,
        },
        [breakpoints.down("xs")]: {
          fontSize: 16,
        },
      },
      subtitle2: {
        fontSize: 16,
        fontWeight: "unset",
        [breakpoints.down("sm")]: {
          fontSize: 15,
        },
        [breakpoints.down("xs")]: {
          fontSize: 14,
        },
      },
      body1: {
        fontSize: 14,
        fontWeight: "unset",
        [breakpoints.down("sm")]: {
          fontSize: 13,
        },
        [breakpoints.down("xs")]: {
          fontSize: 12,
        },
      },
      body2: {
        fontSize: 12,
        fontWeight: "unset",
        [breakpoints.down("sm")]: {
          fontSize: 11,
        },
        [breakpoints.down("xs")]: {
          fontSize: 10,
        },
      },
    },
    // MuiAppBar: {
    //   root: {
    //     backgroundColor: '#161616 !important',
    //   },
    // },
    MuiButton: {
      root: {
        textTransform: "uppercase",
      },
      containedPrimary: {
        backgroundColor: "#c29032",
        color: "#fff",
        fontSize: 12,
        borderRadius: "50px",
        fontWeight: 400,
        padding: "16px 24px 16px 24px",
        "&:hover": {
          color: "#fff",
          backgroundColor: "#000",
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
