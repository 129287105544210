/**
 * /* eslint-disable jsx-a11y/iframe-has-title
 *
 * @format
 */

/** @format */

import emailjs from "@emailjs/browser";
import {
  Box,
  Button,
  Container,
  Grid,
  Link,
  Snackbar,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MuiAlert from "@material-ui/lab/Alert";
import { motion } from "framer-motion";
import React, { useState } from "react";
import {
  EMAILJS_CONTACT_TEMPLATE_ID,
  EMAILJS_PUBLIC_KEY,
  EMAILJS_SERVICE_ID,
} from "../../constants";
import Phone from "./../../assets/sample/clock.png";
import Envelope from "./../../assets/sample/envelope.png";
import Office from "./../../assets/sample/office.png";
import Clock from "./../../assets/sample/phone.png";

const useStyles = makeStyles((theme) => ({
  contactUsForm: {
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(6),
    },
  },
  contactUsFormWrapper: {
    display: "flex",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(1),
    },
  },
  contactUsFormIcons: {
    height: 32,
    display: "block",
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      height: 28,
      marginTop: theme.spacing(0.75),
      marginRight: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      height: 24,
      marginTop: theme.spacing(0.5),
      marginRight: theme.spacing(1),
    },
  },
  contactUsFormTitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
  },
  contactUsFormSubtitle: {
    fontFamily: "Playfair Display",
    fontWeight: 500,
    color: "#000",
  },
  contactUsFormTagline: {
    fontFamily: "Mukta",
    fontWeight: 400,
    color: "#777",
  },
  contactUsFormBody: {
    fontFamily: "Mukta",
    color: "#777",
    fontWeight: 400,
    "& a": {
      fontFamily: "Mukta",
      color: "#777",
      fontWeight: 400,
      "&:hover": {
        textDecorationColor: "#777",
      },
    },
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}>
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SampleContactUsForm(props) {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [verify, setVerify] = useState(false);
  const [success, setSuccess] = useState(false);

  const handleSubmit = async () => {
    console.log("First Name: ", firstName);
    console.log("Last Name: ", lastName);
    console.log("Mobile: ", mobile);
    console.log("Email: ", email);
    console.log("Message: ", message);

    if (!firstName || !lastName || !mobile || !email || !message) {
      setVerify(true);
      return;
    }

    const afterContactMail = await emailjs.send(
      EMAILJS_SERVICE_ID,
      EMAILJS_CONTACT_TEMPLATE_ID,
      {
        firstName,
        lastName,
        email,
        mobile,
        message,
      },
      EMAILJS_PUBLIC_KEY
    );

    console.log("After Contact Email Response: ", afterContactMail);

    setSuccess(true);

    setFirstName("");
    setLastName("");
    setEmail("");
    setMobile("");
    setMessage("");
  };

  return (
    <div className={classes.contactUsForm}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={6}>
            <motion.div
              >
              <Typography
                variant="h2"
                component="h2"
                className={classes.contactUsFormTitle}>
                Our Contact Info
              </Typography>
            </motion.div>
            {/* <motion.div
              className={classes.contactUsFormWrapper}>
              <img
                alt="text"
                src={Office}
                className={classes.contactUsFormIcons}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.contactUsFormSubtitle}>
                  Nariman Point:
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.contactUsFormBody}>
                  606 | Dalamal Towers | A- Wing | 211 | Free Press Journal Marg
                  | Nariman Point | Mumbai: 400 021.<br/>Phone: +91 22497 99799 |
                  Fax: +91 22 3916 7112.
                </Typography>
              </div>
            </motion.div> */}
            <motion.div
              className={classes.contactUsFormWrapper}>
              <img
                alt="text"
                src={Office}
                className={classes.contactUsFormIcons}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.contactUsFormSubtitle}>
                  Vashi:
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.contactUsFormBody}>
                  407/412 | Satra Plaza | Palm Beach Road | Sector-19D | Vashi|
                  Navi Mumbai: 400 703.<br/>Phone: +91 22 2783 4407 | Fax: +91 2783
                  4408
                </Typography>
              </div>
            </motion.div>
            <motion.div
              className={classes.contactUsFormWrapper}>
              <img
                alt="text"
                src={Clock}
                className={classes.contactUsFormIcons}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.contactUsFormSubtitle}>
                  Hours
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.contactUsFormBody}>
                  Mon-Fri: 08.00 - 19.00
                </Typography>
              </div>
            </motion.div>
            <motion.div
          
              className={classes.contactUsFormWrapper}>
              <img
                alt="text"
                src={Phone}
                className={classes.contactUsFormIcons}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.contactUsFormSubtitle}>
                  Phone Number
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.contactUsFormBody}>
                  <Link href="tel:+02227834407" target="_blank" rel="noreferrer">
                  022 2783 4407
                  </Link>
                </Typography>
              </div>
            </motion.div>
            <motion.div
        
              className={classes.contactUsFormWrapper}>
              <img
                alt="text"
                src={Envelope}
                className={classes.contactUsFormIcons}
              />
              <div>
                <Typography
                  variant="subtitle1"
                  className={classes.contactUsFormSubtitle}>
                  Email
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={classes.contactUsFormBody}>
                  <Link
                    href="mailto:mail@lawcounsellors.in"
                    target="_blank"
                    rel="noreferrer">
                    mail@lawcounsellors.in
                  </Link>
                </Typography>
              </div>
            </motion.div>
            {/* <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 1, delay: 2.5 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
              className={classes.contactUsFormWrapper}>
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example">
                <Tab label="Vashi Location" {...a11yProps(0)} />
                <Tab label="Nariman Point Location" {...a11yProps(1)} />
              </Tabs>
            </motion.div> */}
            {/* <TabPanel value={value} index={0}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120658.24046941224!2d72.9360945429319!3d19.08263377315787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3be7c13a63c6f08b%3A0x2b2bab694f6c2019!2s407%2C%20Satra%20Plaza%2C%20Palm%20Beach%20Road%2C%20Sector-19D%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!3m2!1d19.082646999999998!2d73.006135!5e0!3m2!1sen!2sin!4v1655371887277!5m2!1sen!2sin"
                width="400"
                height="300"
                title="Vashi Location"
                allowfullscreen
                style={{ border: 0 }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7548.306981771955!2d72.82428759999999!3d18.924599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1ebd7699173%3A0x693cf9993130cc2a!2sLaw%20Counsellors%20%7C%20Advocates%20%26%20Solicitors!5e0!3m2!1sen!2sin!4v1655371930860!5m2!1sen!2sin"
                width="400"
                height="300"
                style={{ border: 0 }}
                allowfullscreen
                title="Nariman Point Location"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </TabPanel> */}
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <div>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <motion.div>
                    <Typography
                      variant="h2"
                      component="h2"
                      className={classes.contactUsFormTitle}>
                      Send us your Message:
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid item xs={12}>
                  <motion.div>
                    <Typography
                      variant="subtitle2"
                      className={classes.contactUsFormTagline}>
                      To comply with regulations stipulated by the Bar Council
                      of India that does not permit solicitation in any form or
                      manner, by accessing this site you acknowledge that you
                      are seeking information of your own accord and volition
                      and that no form of solicitation has taken place by “Law
                      Counsellors” or its members. This site
                      (www.lawcounsellors.in) should not be construed as an
                      attempt to create a lawyer-client relationship of any
                      manner.
                    </Typography>
                  </motion.div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <motion.div>
                    <TextField
                      placeholder="First Name"
                      variant="outlined"
                      fullWidth
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                  </motion.div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <motion.div>
                    <TextField
                      placeholder="Last Name"
                      variant="outlined"
                      fullWidth
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                    />
                  </motion.div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <motion.div>
                    <TextField
                      placeholder="Contact Number"
                      variant="outlined"
                      fullWidth
                      value={mobile}
                      onChange={(e) => setMobile(e.target.value)}
                    />
                  </motion.div>
                </Grid>
                <Grid item xs={6} sm={6} md={6}>
                  <motion.div>
                    <TextField
                      placeholder="Email Address"
                      variant="outlined"
                      fullWidth
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </motion.div>
                </Grid>
                <Grid item xs={12}>
                  <motion.div>
                    <TextField
                      multiline
                      rows={4}
                      placeholder="Your Message"
                      variant="outlined"
                      fullWidth
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                    />
                  </motion.div>
                </Grid>
                <Grid item xs={12}>
                  <motion.div>
                    <Button
                      variant="contained"
                      size="large"
                      color="primary"
                      disableElevation
                      onClick={handleSubmit}>
                      Submit
                    </Button>
                  </motion.div>
                </Grid>
              </Grid>
            </div>
          </Grid>
          <Grid item xs={12}>
          <Tabs
                value={value}
                onChange={handleChange}
                textColor="primary"
                indicatorColor="primary"
                aria-label="secondary tabs example">
                <Tab label="Vashi Location" {...a11yProps(0)} />
                {/* <Tab label="Nariman Point Location" {...a11yProps(1)} /> */}
              </Tabs>
            <TabPanel value={value} index={0}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m23!1m12!1m3!1d120658.24046941224!2d72.9360945429319!3d19.08263377315787!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m8!3e6!4m0!4m5!1s0x3be7c13a63c6f08b%3A0x2b2bab694f6c2019!2s407%2C%20Satra%20Plaza%2C%20Palm%20Beach%20Road%2C%20Sector-19D%2C%20Vashi%2C%20Navi%20Mumbai%2C%20Maharashtra%20400703!3m2!1d19.082646999999998!2d73.006135!5e0!3m2!1sen!2sin!4v1655371887277!5m2!1sen!2sin"
                width="100%"
                height="400"
                title="Vashi Location"
                allowfullscreen
                style={{ border: 0 }}
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </TabPanel>
            {/* <TabPanel value={value} index={1}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7548.306981771955!2d72.82428759999999!3d18.924599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7d1ebd7699173%3A0x693cf9993130cc2a!2sLaw%20Counsellors%20%7C%20Advocates%20%26%20Solicitors!5e0!3m2!1sen!2sin!4v1655371930860!5m2!1sen!2sin"
                width="100%"
                height="400"
                style={{ border: 0 }}
                allowfullscreen
                title="Nariman Point Location"
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              />
            </TabPanel> */}
          </Grid>
        </Grid>
        <Snackbar
          open={verify}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={() => setVerify(false)}>
          <Alert
            onClose={() => setVerify(false)}
            severity="info"
            sx={{ width: "100%" }}>
            Please fill all the details in the form to submit
          </Alert>
        </Snackbar>
        <Snackbar
          open={success}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
          onClose={() => setSuccess(false)}>
          <Alert
            onClose={() => setSuccess(false)}
            severity="success"
            sx={{ width: "100%" }}>
            Your Form has been submitted!
          </Alert>
        </Snackbar>
      </Container>
    </div>
  );
}
