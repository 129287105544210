/** @format */
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { team } from "../../lib/team";

const useStyles = makeStyles((theme) => ({
  meetOurTeamHome: {
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    backgroundColor: "#f4f4f4",
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(9),
      paddingBottom: theme.spacing(9),
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(8),
      paddingBottom: theme.spacing(8),
      paddingLeft: "unset",
      paddingRight: "unset",
    },
  },
  meetOurTeamHomePreTitleWrapper: {
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(7),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(6),
    },
  },
  meetOurTeamHomePretitle: {
    fontFamily: "Mukta",
    color: "#c29032",
    fontWeight: 400,
    [theme.breakpoints.down("sm")]: {
      textAlign:'center',
    },
  },
  meetOurTeamHomeTitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      textAlign:'center',
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      textAlign:'center',
    },
  },
  meetOurTeamBoxTitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      textAlign:'center',
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      textAlign:'center',
    },
  },
  meetOurTeamBox: {
    backgroundColor: "#fff",
    padding: theme.spacing(4),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    borderBottom: "3px solid #fff",
    boxShadow: "0 25px 75px rgb(0 0 0 / 5%)",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(2),
    },
  },

  // meetOurTeamBoxTitle: {
  //   fontFamily: "Mukta",
  //   color: "rgb(0,0,0)",
  //   fontWeight: 500,
  // },
}));

const imageDict = [
  {
    id: "akhilesh-dubey",
    image: require("../../assets/newImages/akhileshSirLowRes.jpeg"),
  },
  {
    id: "vagish-mishra",
    image: require("../../assets/newImages/vagishSir.jpg"),
  },
  {
    id: "rajendra-singh",
    image: require("../../assets/newImages/transparent.png"),
  },
  // {
  //   id: "ahmad-ali-khan",
  //   image: require("../../assets/newImages/transparent.png"),
  // },
  {
    id: "paresh-bhoir",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "swapnil-irpanwar",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "amit-dubey",
    image: require("../../assets/newImages/amitDubey.jpg"),
  },
  {
    id: "uttam-dubey",
    image: require("../../assets/newImages/uttamSir.jpg"),
  },
  //  {
  //   id: "rani-tripathi",
  //   image: require("../../assets/newImages/transparent.png"),
  // },
  {
    id: "mehz-shaikh",
    image: require("../../assets/newImages/mehez.jpg"),
  },
  {
    id: "ashish-gharat",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "rahul-sharma",
    image: require("../../assets/newImages/rahulSharma.jpg"),
  },
  {
    id: "alka-singh",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "rajuram-kuleriya",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "varay-dubey",
    image: require("../../assets/newImages/varadDubey.jfif"),
  },
  {
    id: "ritika-gupta",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "suhas-kadu",
    image: require("../../assets/newImages/transparent.png"),
  },
  {
    id: "nidhi-pandey",
    image: require("../../assets/newImages/niddhiPandey.jpeg"),
  },
  {
    id: "gayatri-patel",
    image: require("../../assets/newImages/gayatriPatel.jfif"),
  },
  {
    id: "nikita-sharma",
    image: require("../../assets/newImages/nikitaSharma.jpg"),
  },
];

export default function MeetOurTeamHome(props) {
  const classes = useStyles();
  const navigate = useNavigate();

  return (
    <div className={classes.meetOurTeamHome}>
      <Container>
        <Grid container justifyContent="center" className={classes.meetOurTeamHomePreTitleWrapper}>
          <Grid item xs={12}>
            <motion.div
            >
              <Typography
                variant="body1"
                className={classes.meetOurTeamHomePretitle}>
                MEET OUR
              </Typography>
              <Typography
                className={classes.meetOurTeamHomeTitle}
                variant="h5"
                component="h2">
                LEGAL TEAM
              </Typography>
            </motion.div>
          </Grid>
        </Grid>
        <Grid container justifyContent="center" spacing={4}>
          {team.map((item) => {
            return (
              <Grid item xs={12} sm={8} md={4} key={item.id}>
                <div
                     onClick={() => {
                      if(item.flag!==0){
                        navigate(`/TeamInfo?${item.id}`, {
                          state: { team: item, imageDict },
                        });
                        window.scrollTo({
                          top: 0,
                          behavior: 'smooth'
                        });
                      }
                    }}
                    >
                <div
                  className={classes.meetOurTeamBox}
                  >
                  <Typography
                    className={classes.meetOurTeamBoxTitle}
                    variant="subtitle1">
               
                   {item.title}
                  </Typography>
                </div>
                </div>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </div>
  );
}
