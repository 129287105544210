/** @format */
import { Container, Grid, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  meetOurTeamHero: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(9),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(8),
    },
  },
  meetOurTeamPretitle: {
    fontFamily: "Mukta",
    color: "#c29032",
    fontWeight: 400,
  },
  meetOurTeamTitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1.5),
    },
  },
  meetOurTeamHeroImg: {
    width: "100%",
    display: "block",
  },
  meetOurTeamSubtitle: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1.5),
    },
  },
  meetOurTeamName: {
    fontFamily: "Playfair Display",
    color: "rgb(0,0,0)",
    fontWeight: 500,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  meetOurTeamBody: {
    fontFamily: "Mukta",
    color: "#777",
    fontWeight: 400,
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
}));

export default function MeetOurTeamHero(props) {
  const classes = useStyles();
  const location = useLocation();
  
  console.log("Location State: ", location.state);
  const { desc } = location.state.team;
  window.scrollTo(0,0);
  return (
    <div className={classes.meetOurTeamHero}>
      <Container>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography className={classes.meetOurTeamPretitle}>
              {desc.title}
            </Typography>
            <Typography
              variant="h2"
              component="h1"
              className={classes.meetOurTeamTitle}>
              {desc.name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={5}>
            <img
              alt="text"
              src={
                location.state.imageDict.filter(
                  (item) => item.id === location.state.team.id
                )[0].image
              }
              className={classes.meetOurTeamHeroImg}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={7}>
            <Typography variant="subtitle2" className={classes.meetOurTeamBody}>
              {desc.body1}
            </Typography>
            <Typography variant="subtitle2" className={classes.meetOurTeamBody}>
              {desc.body2}
            </Typography>
            <Typography variant="subtitle2" className={classes.meetOurTeamBody}>
              {desc.body3}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
