import React from 'react';
// import MeetOurTeamHero from './MeetOurTeamHero';
import MeetOurTeamHome from './MeetOurTeamHome';
// import MeetOurExperts from './MeetOurExperts';
// import MeetOurCoreTeam from './MeetOurCoreTeam';
// import { makeStyles } from '@material-ui/core/styles';

// const useStyles = makeStyles((theme) => ({
//     team: {
//         padding: theme.spacing(5),
//         [theme.breakpoints.down('sm')]: {
//             padding: theme.spacing(3),
//         },
//         [theme.breakpoints.down('xs')]: {
//             padding: theme.spacing(0),
//         },
//     }
// }));
export default function MeetOurTeam(props) {
    // const classes = useStyles();
    // const theme = useTheme();
    return (
        <MeetOurTeamHome/>
        // <div className={classes.team}>
        //     <MeetOurTeamHero />
        //     <MeetOurExperts/>
        //     <MeetOurCoreTeam/>
        // </div>
    );
}