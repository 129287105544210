import React from 'react';
import { Grid, Typography, Container} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import PhilosophyBackground from '../../assets/sample/philosophyBackground.jpeg';
import { motion } from 'framer-motion';
const useStyles = makeStyles((theme) => ({
    philosophyHero: {
        background: `url(${PhilosophyBackground})`,
        paddingTop: theme.spacing(10),
        paddingBottom: theme.spacing(10),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        [theme.breakpoints.down("sm")]: {
            // marginTop: theme.spacing(9),
            // marginBottom: theme.spacing(9),
            paddingLeft: theme.spacing(4),
            paddingRight: theme.spacing(4),
        },
        [theme.breakpoints.down("xs")]: {
            // marginTop: theme.spacing(8),
            // marginBottom: theme.spacing(8),
            paddingLeft: theme.spacing(0),
            paddingRight: theme.spacing(0),
        },
    },
    philosophyPretitle: {
        fontFamily: 'Mukta',
        color: '#c29032',
        textAlign: "center",
        fontWeight: 400,
    },
    philosophyTitle: {
        fontFamily: 'Playfair Display',
        color: 'rgb(0,0,0)',
        textAlign: "center",
        fontWeight: 500,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(1.5),
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1.5),
        },
    },
    philosophyHeroImg: {
        width: '100%',
        maxHeight: '500px',
        display: 'block',
    },
    philosophySubtitle: {
        fontFamily: 'Playfair Display',
        color: 'rgb(0,0,0)',
        fontWeight: 500,
        marginBottom: theme.spacing(3),
        [theme.breakpoints.down("sm")]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down("xs")]: {
            marginBottom: theme.spacing(1.5),
        },
    },
    philosophyBody: {
        fontFamily: 'Mukta',
        color: '#777',
        textAlign: "justify",
        fontWeight: 400,
        // marginBottom: theme.spacing(2),
        // [theme.breakpoints.down("sm")]: {
        //     marginBottom: theme.spacing(1.5),
        // },
        // [theme.breakpoints.down("xs")]: {
        //     marginBottom: theme.spacing(1),
        // },
    },
}));

export default function TestPhilosophy(props) {

    const classes = useStyles();

    return (
        <div className={classes.philosophyHero}>
            <Container >
                <Grid container alignItems='center' justifyContent='center' spacing={4}>
                    <Grid item xs={12} sm={10} md={8}>
                        <motion.div className={classes.caseStudiesWrapper}
                            >
                        <Typography variant="body1" className={classes.philosophyPretitle}>OUR CORE VALUES</Typography>
                        <Typography variant="h2" component="h1" className={classes.philosophyTitle}>Philosphy</Typography>
                        </motion.div>
                    </Grid>
                    <Grid item xs={12} sm={10} md={8}>
                        <motion.div className={classes.caseStudiesWrapper}
                            >
                        {/* <Typography variant="h2" className={classes.philosophySubtitle}>About Us</Typography> */}
                        <Typography variant="subtitle1" className={classes.philosophyBody}>At Law Counsellors, our reputation is our most valuable asset. We earn it every day with the choices we make – for our clients, our Firm, our communities and ourselves.
                            <br />
                            <br />
                            Law Counsellors is committed to offering quality legal services economically. We understand that obtaining superior results and controlling the cost of legal services are often competing priorities for our clients. As a result, we have devoted time and thought to structuring our firm and educating our attorneys so that we achieve our goal of providing the highest quality legal services at the most reasonable cost to the client.
                            <br />
                            <br />
                            We consider Lawyer experience, so that our partner-to-associate ratio utilizes proper balance for the most efficient allocation of talent on each file. The partner in charge always remains responsible for all work performed. We also use non-legal personnel to handle tasks that do not require specialized legal knowledge
                            <br />
                            <br />
                            We provide our clients with a preliminary analysis at the earliest possible stage to allow informed decisions regarding litigation or possible settlement, and advocate total client involvement at every step in the litigation process
                            <br />
                            <br />
                            We follow advances in technology and make efficient use of state-of-the-art computer systems, internet communications, litigation support databases and on-line research services
                            <br />
                            <br />
                            We research and prepare for best results from the start of each new client matter through careful attention to detail, thorough preparation and aggressive case handling. We evaluate opportunities for settlement early and often, while taking steps to insure each case will be ready for trial, if necessary.
                            <br />
                            <br />
                            We often recommend Alternative Dispute Resolution, including private and court-sponsored arbitration, mediation and neutral case evaluation as cost-effective means of achieving client goals.
                            <br />
                            <br />
                            We believe in promoting professionalism and civility in all aspects of our legal representation.</Typography>
                            </motion.div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}