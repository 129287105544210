/** @format */

import { Button, Container, Grid, Link, Typography } from "@material-ui/core";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import MenuIcon from "@material-ui/icons/Menu";
import StarRateIcon from "@material-ui/icons/StarRate";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import LawCounsellorEdge from "./assets/sample/lawCounsellorsEdge.png";
import MeetOurTeam from "./pages/meetOurTeam/Index";
import MeetOurTeamHero from "./pages/meetOurTeam/MeetOurTeamHero";
import PrivacyPolicy from "./pages/privacyPolicy/Index";
import SampleCareers from "./pages/sampleCareers/Index";
import SampleContact from "./pages/sampleContact/Index";
import TestHome from "./pages/testHome/Index";

const drawerWidth = "20%";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },

  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    backgroundColor: "#1b1b1b",
    width: drawerWidth,
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      padding: theme.spacing(4, 2),
      width: 220,
    },
  },
  content: {
    flexGrow: 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    [theme.breakpoints.down("xs")]: {
      width: "unset",
    },
  },
  drawerLogo: {
    width: "100%",
    display: "block",
    maxWidth: 160,
    margin: "0 auto",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "136px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "144px",
    },
  },
  drawerNameWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    "& $drawerName:last-child": {
      marginBottom: 0,
    },
  },
  drawerName: {
    fontFamily: "playfair Display",
    fontWeight: 400,
    color: "#999",
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(2),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
    "& svg": {
      fontSize: 16,
      marginRight: 8,
      [theme.breakpoints.down("sm")]: {
        fontSize: 14,
        marginRight: 6,
      },
      [theme.breakpoints.down("xs")]: {
        fontSize: 12,
        marginRight: 4,
      },
    },
    "&:hover": {
      color: "#c29032",
      textDecoration: "none",
    },
  },

  drawerNameActive: {
    color: "#c29032",
  },

  drawerContactTitle: {
    color: "#c29032",
    fontWeight: 400,
    fontFamily: "Mukta",
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(1),
    },
  },
  drawerContactValue: {
    color: "#999",
    fontWeight: 500,
    fontFamily: "Mukta",
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: theme.spacing(2),
    },
  },

  drawerSocialWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1.5),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(1),
    },
  },
  dividerLine: {
    backgroundColor: "rgba(255, 255, 255, 0.1)",
    height: 1,
    flex: 1,
  },
  socialIcons: {
    fill: "#c29032",
    width: 40,
    height: 40,
    display: "block",
    border: "1px solid rgba(255, 255, 255, 0.1)",
    padding: theme.spacing(1),
    borderRadius: 50,
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginLeft: theme.spacing(0.75),
      marginRight: theme.spacing(0.75),
    },
    [theme.breakpoints.down("xs")]: {
      marginLeft: theme.spacing(0.5),
      marginRight: theme.spacing(0.5),
    },
    "&:hover": {
      border: "1px solid #c29032",
    },
  },
  disclaimerOverflow: {
    height: "400px",
    Overflow: "scroll",
  },
  disclaimerTitle: {
    color: "#000000",
    fontWeight: 500,
    fontFamily: "Mukta",
  },
  disclaimerText: {
    color: "#000000",
    fontWeight: "500",
    fontFamily: "Playfair Display",
  },
  disclaimerFlex: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  footer: {
    backgroundColor: "#282828",
    paddingTop: theme.spacing(5),
    paddingBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
    },
  },
  footerLogoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  footerLogo: {
    maxWidth: "160px",
    width: "100%",
    display: "block",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "136px",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "104px",
    },
  },
  footerDivider: {
    backgroundColor: "#777",
    width: "100%",
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  allRightsReserved: {
    color: "#777",
    textAlign: "center",
  },
  drawerLink:{
    textDecoration:'none',
  },
}));

function ResponsiveDrawer(props) {
  // const { window } = props;
  const classes = useStyles();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    if (!sessionStorage.getItem("newUser")) {
      setOpen(true);
      sessionStorage.setItem("newUser", false);
    }
  }, []);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="transparent"
        elevation={0}
        className={classes.appBar}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}>
            <MenuIcon style={{ fontSize: 40 }}/>
          </IconButton>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Hidden smUp implementation="css">
          <Drawer
            // container={container}
            variant="temporary"
            // anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            // ModalProps={{
            //   keepMounted: true, // Better open performance on mobile.
            // }}
          >
            <img
              src={LawCounsellorEdge}
              alt="logo"
              className={classes.drawerLogo}></img>
            {/* </div> */}
            <div className={classes.drawerNameWrapper}>
              <Link variant="subtitle1" className={classes.drawerName} href="/">
                <StarRateIcon /> Home
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="/Team">
                <StarRateIcon />
                Team
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="Careers">
                <StarRateIcon /> Careers
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="Contact">
                <StarRateIcon /> Contact
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="https://lawcounsellors.in:2096"
                target="_blank"
                rel="noopener noreferrer">
                <StarRateIcon /> Lawyers Login
              </Link>
            </div>
            <>
              <Typography
                variant="body1"
                className={classes.drawerContactTitle}>
                CALL US
              </Typography>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="tel:02227834407"
                target="_blank"
                underline="none"
                rel="noopener noreferrer">
              <Typography
                variant="subtitle1"
                className={classes.drawerContactValue}>
                  +91 022 2783 4407
              </Typography>
              </Link>
              <Typography
                variant="body1"
                className={classes.drawerContactTitle}>
                EMAIL US
              </Typography>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="mailto:mail@lawcounsellors.in"
                target="_blank"
                underline="none"
                rel="noopener noreferrer">
              <Typography
                variant="subtitle1"
                className={classes.drawerContactValue}>
                mail@lawcounsellors.in
              </Typography>
              </Link>
            </>
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open>
            {/* <div className={classes.drawerLogoWrapper}> */}
            <img
              src={LawCounsellorEdge}
              alt="logo"
              className={classes.drawerLogo}></img>
            {/* </div> */}
            <div className={classes.drawerNameWrapper}>
              <Link variant="subtitle1" className={classes.drawerName} href="/">
                <StarRateIcon /> Home
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="/Team">
                <StarRateIcon />
                Team
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="Careers">
                <StarRateIcon /> Careers
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="Contact">
                <StarRateIcon /> Contact
              </Link>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="https://lawcounsellors.in:2096"
                target="_blank"
                rel="noopener noreferrer">
                <StarRateIcon /> Lawyers Login
              </Link>
            </div>
            <>
              <Typography
                variant="body1"
                className={classes.drawerContactTitle}>
                CALL US
              </Typography>
              <Link
                variant="subtitle1"
                className={classes.drawerLink}
                href="tel:02227834407"
                target="_blank"
                underline="none"
                rel="noopener noreferrer">
              <Typography
                variant="subtitle1"
                className={classes.drawerContactValue}>
                 +91 022 2783 4407
              </Typography>
              </Link>
              <Typography
                variant="body1"
                className={classes.drawerContactTitle}>
                EMAIL US
              </Typography>
              <Link
                variant="subtitle1"
                className={classes.drawerLink}
                href="mailto:mail@lawcounsellors.in"
                target="_blank"
                underline="none"
                rel="noopener noreferrer">
              <Typography
                variant="subtitle1"
                className={classes.drawerContactValue}>
                mail@lawcounsellors.in
              </Typography>
              </Link>
            </>
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <Routes>
          <Route path="/" exact element={<TestHome />} />
          <Route path="/Home" element={<TestHome />} />
          <Route path="/Team" element={<MeetOurTeam />} />
          <Route path="/Contact" element={<SampleContact />} />
          <Route path="/Careers" element={<SampleCareers />} />
          <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          <Route path="/TeamInfo" element={<MeetOurTeamHero />} />
        </Routes>
        <div className={classes.footer}>
          <Container>
            <Grid container>
              <Grid item xs={12}>
                <div className={classes.footerLogoWrapper}>
                  <img
                    src={LawCounsellorEdge}
                    alt="logo"
                    className={classes.footerLogo}
                  />
                </div>
              </Grid>
            </Grid>
            <Divider className={classes.footerDivider} />
            <Grid
              container
              spacing={4}
              alignItems="center"
              justifyContent="center">
                <Grid item>
                <Link variant="subtitle1" className={classes.drawerName} href="/">
                <StarRateIcon /> Home
              </Link>
                </Grid>
                <Grid item>
                <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="/Team">
                <StarRateIcon />
                Team
              </Link>
              </Grid>
              <Grid item>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="Careers">
                <StarRateIcon /> Careers
              </Link>
              </Grid>
              <Grid item>
              <Link
                variant="subtitle1"
                className={classes.drawerName}
                href="Contact">
                <StarRateIcon /> Contact
              </Link>
                </Grid>
              <Grid item xs={12}>
                <Link variant="body2" className={classes.allRightsReserved}>
                  © 2020 LAW COUNSELLORS..
                </Link>
              </Grid>
            </Grid>
          </Container>
          <Dialog
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="responsive-dialog-title">
            <DialogTitle id="responsive-dialog-title">
              <Typography variant="h4" className={classes.disclaimerTitle}>
                Disclaimer
              </Typography>
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                <div className={classes.disclaimerOverflow}>
                  <Typography
                    variant="subtitle1"
                    className={classes.disclaimerText}>
                    The Bar Council of India, whose rules govern our practices
                    has held that web sites, infringe the present Professional
                    Conduct Rules, if any information relating to legal
                    practitioners is hosted on the web. While we may not be in
                    agreement with this view, what we hold in high esteem, is to
                    maintain discipline by following the Bar Council’s mandate
                    by maintaining the present website only for contact
                    information and internal communication between Akhilesh
                    Dubey and his Associates/Existing clients. No
                    Attorney-Client Relationship Created by Use of this Website:
                    Neither your receipt of information from this website, nor
                    your use of this website to contact Law Counsellors and their
                    Associates, creates an attorney-client relationship between
                    you and Law Counsellors and their Associates. As a matter of
                    policy, Law Counsellors and their Associates do not accept a
                    new client without first investigating for possible
                    conflicts of interests and obtaining a signed engagement
                    letter and/or Vakalatnama. Accordingly, you should not use
                    this website to provide confidential information about a
                    legal matter of yours to Law Counsellors and their Associates.
                    No Legal Advice Intended: This website is only for contact
                    information and internal communication between Law Counsellors 
                    and their Associates/existing clients. Any material
                    found on this website are for informational purposes only
                    and may not reflect the most current legal developments.
                    These informational materials are not intended, and should
                    not be taken, as legal advice on any particular set of facts
                    or circumstances. You should contact an advocate for advice
                    on specific legal problems. IF YOU DO NOT AGREE WITH THESE
                    TERMS AND CONDITIONS, PLEASE DO NOT USE THIS SITE. By
                    continuing to use the Site and/or any of the services shown
                    on the Site, you agree to be bound by these Terms and
                    Conditions.
                  </Typography>
                </div>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className={classes.disclaimerFlex}>
                <Button
                  variant="contained"
                  onClick={() => setOpen(false)}
                  color="primary">
                  I Agree
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
      </main>
    </div>
  );
}

ResponsiveDrawer.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default ResponsiveDrawer;
